.buttonFocused {
  outline: 2px solid #368fe9;
  outline-offset: 2px;
  border-radius: 80px;
  padding-left: 8px;
  padding-right: 8px;
}

.accordianContainer:focus-visible {
  outline: 2px solid #368fe9 !important;
  outline-offset: 2px;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

.accordianContainer[class~="staysure"]:hover p {
  color: theme("colors.secondary-active-hover") !important;
}

.accordianContainer[class~="staysure"]:hover svg path {
  stroke: theme("colors.secondary-active-hover") !important;
}

.accordianContainer[class~="expat"]:hover p {
  color: theme("colors.secondary-active-hover") !important;
}

.accordianContainer[class~="expat"]:hover svg path {
  stroke: theme("colors.secondary-active-hover") !important;
}

.accordianContainer[class~="avanti"]:hover p {
  color: theme("colors.interactive-secondary-hovered") !important;
}

.accordianContainer[class~="avanti"]:hover svg path {
  stroke: theme("colors.interactive-secondary-hovered") !important;
}
