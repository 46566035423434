.date_picker_wrapper {
  @apply z-10;
}

.date_picker_wrapper [class~="cal-module_defaultClass__P643k"] {
  @apply w-full m-0  justify-between;
}

.date_picker_wrapper [class~="cal-module_HintClass__3T4iR"] {
  @apply hidden;
}

.date_picker_wrapper [class~="MuiStack-root"] [class~="MuiFormLabel-root"] {
  @apply leading-[24px] !font-body-font;
}

.date_picker_wrapper
  [class~="MuiStack-root"]
  [class~="MuiInputLabel-shrink"][class~="MuiFormLabel-root"] {
  @apply font-medium !text-text-functional;
}

.date_picker_wrapper
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiPickersCalendarHeader-label"],
.date_picker_wrapper
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersCalendarHeader-label"] {
  @apply mr-0 !font-body-font leading-[27px];
}

.date_picker_wrapper
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiButtonBase-root"][class~="MuiDateRangePickerDay-day"]:hover {
  @apply !rounded-radius-lg bg-interactive-primary-hovered border-interactive-primary-hovered;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiButtonBase-root"][class~="Mui-disabled"] {
  @apply !text-surface-neutral-option-5;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiButtonBase-root"][class~="Mui-disabled"]:hover {
  @apply !bg-transparent !font-normal;
}

.date_picker_wrapper
  [class~="cal-module_defaultClass__P643k"]
  [class~="MuiInputBase-root"]
  [class~="MuiInputBase-input"],
.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"]
  [class~="MuiInputBase-root"]
  [class~="MuiInputBase-input"] {
  @apply pt-[16px] pb-[16px] !font-body-font leading-[24px] text-2xs;
}

.date_picker_wrapper [class~="cal-module_defaultClass__P643k"] button,
.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"]
  button {
  @apply !h-auto;
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"]
  [class~="MuiInputBase-root"]
  [class~="MuiInputBase-input"] {
  @apply pt-[16px] lg:pl-[24px] pl-[16px] relative top-[1px];
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"][class~="thick-border"]
  [class~="MuiInputBase-root"]
  [class~="MuiInputBase-input"] {
  @apply pt-[15px] lg:pl-[23px] pl-[15px] relative top-[1px];
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"]
  [class~="MuiFormControl-root"][class~="MuiTextField-root"]
  [class~="MuiFormLabel-root"] {
  @apply lg:left-[5px];
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"][class~="thick-border"]
  [class~="MuiFormControl-root"][class~="MuiTextField-root"]
  [class~="MuiFormLabel-root"] {
  @apply !pl-[3px] top-[-1px];
}

.date_picker_wrapper
  [class~="focus-border"]
  [class~="MuiStack-root"]
  [class~="MuiInputLabel-shrink"][class~="MuiFormLabel-root"][class~="MuiInputLabel-root"][class~="Mui-focused"] {
  @apply !text-text-info;
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"][class~="thick-border"]
  [class~="iconCompLibrary_calender_svg_"] {
  @apply relative left-[1px];
}

.date_picker_wrapper
  [class~="cal-module_defaultClass__P643k"]
  [class~="MuiFormControl-root"]:nth-of-type(1)
  [class~="MuiInputLabel-root"] {
  @apply lg:left-[5px] left-[-2px];
}

.date_picker_wrapper
  [class~="cal-module_defaultClass__P643k"]
  [class~="MuiFormControl-root"]:nth-of-type(1)
  [class~="MuiInputLabel-root"][class~="MuiInputLabel-shrink"] {
  @apply left-[4px];
}

.date_picker_wrapper
  [class~="cal-module_defaultClass__P643k"][class~="thick-border"]
  [class~="MuiFormControl-root"]:nth-of-type(2)
  [class~="MuiInputLabel-root"] {
  @apply left-[1px];
}

.date_picker_wrapper
  [class~="cal-module_defaultClass__P643k"]
  [class~="MuiFormControl-root"][class~="MuiFormControl-fullWidth"][class~="MuiTextField-root"]:nth-of-type(
    1
  )
  [class~="MuiInputBase-input"][class~="MuiOutlinedInput-input"] {
  @apply lg:!pl-[24px] !pl-[16px];
}

.date_picker_wrapper
  [class~="cal-module_defaultClass__P643k"][class~="thick-border"] {
  @apply m-[-1px];
}

.date_picker_wrapper
  [class~="cal-module_defaultClass__P643k"][class~="thick-border"]
  [class~="MuiFormControl-root"][class~="MuiFormControl-fullWidth"][class~="MuiTextField-root"]:nth-of-type(
    2
  )
  [class~="MuiInputBase-input"][class~="MuiOutlinedInput-input"] {
  @apply pl-[11px];
}

.date_picker_wrapper
  [class~="cal-module_defaultClass__P643k"][class~="thick-border"]
  [class~="iconCompLibrary_calender_svg_"] {
  @apply relative left-[2px];
}

/* @-moz-document url-prefix() {
  .date_picker_wrapper [class~="cal-module_defaultClass__P643k"]:hover {
    @apply m-[-1.2px];
  }
} */

.date_picker_wrapper [class~="SingleDatePicker-module_defaultClass__5O1TZ"] {
  @apply lg:max-w-[207px] md:max-w-[200px] w-full justify-between;
}

.date_picker_wrapper
  [class~="SingleDatePicker-module_defaultClass__5O1TZ"][type="button"] {
  -webkit-appearance: initial;
  appearance: initial;
}

.date_picker_wrapper [class~="customize_calender_wrapper"] {
  @apply absolute top-[56px] z-10 bg-transparent md:!w-[304px] !w-full;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiDateRangePickerDay-day"] {
  @apply !font-body-font !text-text-functional leading-[24px];
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersDay-root"] {
  @apply !font-body-font !text-text-functional leading-[24px];
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersDay-root"][class~="Mui-disabled"] {
  @apply !text-surface-neutral-option-5;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersDay-root"][class~="Mui-disabled"]:hover {
  @apply !bg-transparent !font-normal;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDayCalendar-header"]
  [class~="MuiTypography-caption"] {
  @apply !font-body-font !text-text-body leading-[24px];
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersDay-root"]:hover {
  @apply !rounded-radius-lg bg-interactive-primary-hovered border-interactive-primary-hovered font-semibold;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiDateRangePickerDay-day"]:hover {
  @apply !rounded-radius-lg bg-interactive-primary-hovered border-interactive-primary-hovered font-semibold;
}

.date_picker_wrapper
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersDay-root"][class~="Mui-selected"][class~="Mui-disabled"] {
  @apply !bg-transparent;
}

.date_picker_wrapper
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiDateRangePickerDay-day"][class~="Mui-selected"][class~="Mui-disabled"] {
  @apply !bg-transparent;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiDateRangePickerDay-day"][class~="MuiDateRangePickerDay-dayInsideRangeInterval"] {
  @apply font-semibold;
}

.date_picker_wrapper
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiDateRangeCalendar-monthContainer"] {
  @apply bg-surface-neutral-option-1 mt-[4px] mx-0 mb-0 md:!w-[304px] !w-full;
}

.date_picker_wrapper
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiDateRangeCalendar-monthContainer"]
  [class~="MuiDayCalendar-weekContainer"] {
  /* @apply m-0; */
}

.date_picker_wrapper
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiPickersCalendarHeader-root"] {
  @apply pl-0 max-h-none mb-[10px] mt-[6px];
}

.date_picker_wrapper [class~="MuiPickersCalendarHeader-root"] {
  @apply mt-[22px];
}

.date_picker_wrapper
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiPickersFadeTransitionGroup-root"] {
  @apply w-full mb-0 pb-[1px] pt-[6px] md:max-w-[304px];
}

.date_picker_wrapper
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiPickersArrowSwitcher-root"] {
  @apply !mb-0 !pb-0 ml-[10px] mt-[10px] border-none align-middle;
}

.date_picker_wrapper [class~="MuiDayCalendar-root"] {
  @apply px-spacing-s;
}

.date_picker_wrapper
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersArrowSwitcher-root"] {
  @apply !border-border-subdued;
}

.date_picker_wrapper
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiPickersArrowSwitcher-root"]
  [class~="MuiIconButton-edgeStart"] {
  @apply pr-[8px] mr-0;
}

.date_picker_wrapper
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiPickersArrowSwitcher-root"]
  [class~="MuiIconButton-edgeEnd"] {
  @apply pl-[8px] ml-0;
}

.date_picker_wrapper
  [class~="css-1u23akw-MuiButtonBase-root-MuiPickersDay-root"]:hover {
  @apply rounded-lg bg-interactive-primary-hovered border-interactive-primary-hovered;
}

.date_picker_wrapper
  [class~="MuiDateRangeCalendar-root"]
  [class~="MuiDayCalendar-header"] {
  @apply border-t-[1px] border-t-border-subdued;
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDayCalendar-slideTransition"] {
  @apply overflow-y-hidden w-full md:mx-[-12px] min-w-[302px] min-h-[254px];
}

.date_picker_wrapper
  [class~="customize_calender_wrapper"]
  [class~="MuiDateCalendar-root"]
  [class~="MuiButtonBase-root"][class~="MuiPickersArrowSwitcher-button"] {
  @apply mb-[10px];
}

.date_picker_wrapper [class~="MuiDateRangePickerDay-rangeIntervalDayPreview"] {
  /* @apply !border-transparent; */
}

.date_picker_wrapper [class~="MuiPickersDay-today"] {
  @apply !border-transparent;
}

.date_picker_wrapper
  [class~="MuiDateCalendar-root"][class~="SingleDatePicker-module_calendarContainer__miVSF"] {
  @apply !p-0 max-h-[360px] md:!w-[304px] !w-full;
}

.date_picker_wrapper
  [class~="MuiDateCalendar-root"]
  [class~="MuiPickersFadeTransitionGroup-root"][class~="MuiDateCalendar-viewTransitionContainer"] {
  @apply mt-[66px] md:w-[300px] w-full;
}

.date_picker_wrapper
  [class~="MuiPickersCalendarHeader-root"]
  [class~="MuiPickersFadeTransitionGroup-root"] {
  @apply md:w-[300px] w-full;
}

@media (max-width: 420px) {
  .date_picker_wrapper
    [class~="customize_calender_wrapper"]
    [class~="MuiDayCalendar-slideTransition"] {
    min-width: auto;
  }

  .date_picker_wrapper [class~="MuiDayCalendar-root"] {
    max-width: 300px;
    margin: 0 auto;
  }

  .date_picker_wrapper
    [class~="customize_calender_wrapper"]
    [class~="MuiDateRangeCalendar-root"]
    [class~="MuiDateRangePickerDay-day"] {
    width: 34px;
    font-size: 14px;
  }
}

@media (max-width: 370px) {
  .date_picker_wrapper
    [class~="customize_calender_wrapper"]
    [class~="MuiDateRangeCalendar-root"]
    [class~="MuiDateRangePickerDay-day"] {
    width: 30px;
    font-size: 12px;
  }

  .date_picker_wrapper
    [class~="customize_calender_wrapper"]
    [class~="MuiDateCalendar-root"]
    [class~="MuiPickersDay-root"] {
    width: 30px;
    font-size: 12px;
  }

  .date_picker_wrapper
    [class~="customize_calender_wrapper"]
    [class~="MuiDateCalendar-root"]
    [class~="MuiTypography-root"] {
    width: 30px;
    font-size: 14px;
  }
}

.text_content {
  @apply text-center mb-spacing-l text-xs leading-[27px] font-normal;
}

.date_picker_modal
  [class~="buttonWrapper_secondary__Xvrv2"][class~="buttonWrapper_focus_class__n1Zsj"][class~="keyBoardFocused"]:focus,
.date_picker_modal [class~="buttonWrapper_secondary__Xvrv2"]:focus {
  @apply border-text-on-secondary;
}
