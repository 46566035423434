.image_block[class~="staysure"] img {
  @apply h-[121px]  rounded-t-radius-xxl md:absolute md:inset-0 md:w-full md:h-full object-cover md:transform md:scale-105 md:transition-transform md:duration-500  w-full;
}

.image_block[class~="expat"] img {
  @apply h-[121px]  rounded-t-radius-xxl md:absolute md:inset-0 md:w-full md:h-full object-cover md:transform md:scale-105 md:transition-transform md:duration-500  w-full;
}

.image_block[class~="avanti"] img {
  @apply h-[152px] w-[250px] md:h-[241px] md:w-[427px] md:absolute md:right-[-82px] mt-[-15px] md:mt-0 md:bottom-[-11px] rounded-t-radius-xxl;
}

.image_area[class~="avanti"] {
  @apply flex justify-center h-[121px] md:h-full;
}

.richtext a {
  word-break: keep-all !important;
  overflow-wrap: normal !important;
}

.referFriendBg[class~="avanti"] {
  @apply md:bg-transparent bg-surface-neutral-option-1;
}
