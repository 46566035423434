.agreement_block p {
    line-height: 24px;
  }
  
  .agreement_block a {
    text-decoration: underline;
    color: theme("colors.interactive-secondary-default");
    font-weight: 600;
    display: inline-block;
  }
  
  .agreement_block a:hover {
    text-decoration: none;
  }
  
  .agreement_block a:focus {
    text-decoration: underline;
    background-color: #30658229;
  }
  
  @media screen and (min-width: 600px) {
    .agreement_block a {
      color: theme("colors.dark.text-on-primary");
    }
  
    .agreement_block a:focus {
      background-color: #30658229;
    }
  }
  