@media screen and (min-width: 600px) {
  .main_wrapper:not(:has(footer)) main,
  .grid_block,
  .question_layout_wrapper,
  .question_layout {
    height: 100%;
  }
}
@media screen and (min-width: 600px) and (orientation: portrait) {
  .homepageWrapper {
    height: 100vh;
    overflow-y: auto;
  }
  .homepageWrapper .main_wrapper {
    height: 100%;
    overflow-y: hidden;
  }
  .main_wrapper:not(:has(footer)) .question_layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .main_wrapper:not(:has(footer)) .question_layout .logo_wrapper {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .main_wrapper:not(:has(footer)) .question_layout .content_box {
    padding-top: 116px;
  }
}
@media screen and (max-width: 600px) {
  .main_wrapper:not(:has(footer)) main {
    padding-bottom: 16px;
  }
}
.medical_page {
  height: 100%;

  @media screen and (min-width: 600px) {
    height: calc(100vh - 12px);
  }
}

.medical_page:before {
  content: "";
  position: absolute;
  width: 100%;
  height: calc(100% - 12px);
  background-color: theme("colors.surface-neutral-option-2");
}

.medical_decline {
  @media screen and (min-width: 600px) {
    height: calc(100vh - 12px);
  }
}

.medical_decline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: calc(100% - 12px);
  background-color: theme("colors.surface-neutral-option-2");
}

.medical_questions {
  height: 100%;
  min-height: calc(100vh - 12px);
}
