.card {
  color: theme("colors.icon-subdued");
  background: theme("colors.surface-neutral-option-1");
  padding: 24px;
  border-radius: 8px;

  @media screen and (max-width: 599px) {
    padding: 16px;
  }
}

.trav_details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.trav_details .trav_name {
  color: theme("colors.text-title");
  font: 400 23px/110% theme("fontFamily.header-font");
  word-break: break-all;
  margin-right: 12px;
}

.trav_details .trav_age {
  color: theme("colors.text-body");
  /* font: 600 18px/150% theme("fontFamily.body-font"); */
  text-align: right;
  flex: 1 0 auto;
}

.trav_med_conditions {
  /* font: 700 18px/150% theme("fontFamily.body-font"); */
  margin-bottom: 16px;
}

.trav_med_conditions ul {
  list-style: none;
  margin: 0;
  padding: 0 !important;
}

.trav_med_conditions ul li {
  color: theme("colors.icon-subdued");
  font-family: theme("fontFamily.body-font");
  font-size: 18px;
  line-height: 150% !important;
  font-weight: 600;
  list-style-type: none !important;
  padding-bottom: 12px;
  border-bottom: 1px solid theme("colors.border-subdued");
  margin-bottom: 12px;

  @media screen and (max-width: 599px) {
    font-weight: 700;
  }
}

.trav_med_conditions ul li:last-of-type {
  padding-bottom: 0;
  border: 0;
  margin-bottom: 0;
}

.button_Wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
  bottom: 0;
  left: 0;
  background-color: theme("colors.interactive-overlay-image");
  column-gap: 16px;
}
.button_Wrapper #medical-dashboard-submit-button {
  width: 244px;
}
@media screen and (min-width: 320px) {
  .button_Wrapper {
    padding-top: 12px;
  }
}
@media screen and (min-width: 600px) {
  .button_Wrapper {
    position: relative;
    padding-top: 32px;
    background-color: transparent;
    box-shadow: none;
    padding-bottom: 0;
  }
  .button_Wrapper #medical-dashboard-submit-button {
    width: 342px;
  }
}
