.textSelectionButton[class~="avanti"] [class~="btnLabel"] {
  background-size: 100% 1px !important;
  background-position: 0 95% !important;
}

.textSelectionButton[class~="staysure"] [class~="btnLabel"] {
  background-size: 100% 1px !important;
  background-position: 0 85% !important;
}

.textSelectionButton[class~="expat"] [class~="btnLabel"] {
  background-size: 100% 1px !important;
  background-position: 0 85% !important;
}

.textSelectionButton[class~="keyBoardFocused"]:focus {
  background-color: rgb(234, 242, 245) !important;
  outline: none !important;
}

.textSelectionButton:hover [class~="btnLabel"] {
  background-size: 0% 2px !important;
  background-color: transparent !important;
}
