.dropdown_btn[class~="componnt_lib_toggle_btn_active"]:focus {
  border-color: theme("colors.interactive-secondary-default") !important;
}

.text_content {
  @apply text-center mb-spacing-l text-xs leading-[27px] font-normal;
}

.same_country_modal
  [class~="buttonWrapper_secondary__Xvrv2"][class~="buttonWrapper_focus_class__n1Zsj"][class~="keyBoardFocused"]:focus,
.same_country_modal [class~="buttonWrapper_secondary__Xvrv2"]:focus {
  @apply border-text-on-secondary;
}

.text_content {
  @apply text-center mb-spacing-l text-xs leading-[27px] font-normal;
}
