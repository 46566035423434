.infoBox a {
  color: theme("colors.interactive-secondary-default");
  text-decoration: underline;
  font-weight: 600;
}

.infoBox a:hover {
  text-decoration: none;
}

.infoBox ul li {
  list-style-type: disc;
}

.infoBox ul {
  margin-left: 16px;
}

.infoBox ol li {
  list-style-type: decimal;
}

.infoBox ol {
  margin-left: 16px;
}
