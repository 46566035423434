.wrapper {
  width: min(100%, 312px);
}

.extraHelp:focus-visible {
  outline: 2px solid theme("colors.interactive-focus-default") !important;
  outline-offset: 2px !important;
  border-radius: 8px !important;
}
@media screen and (max-width: 600px) {
  .wrapper {
    width: calc(100% - 48px) !important;
    left: 0 !important;
    margin: 0 auto;
  }
}
