.agreement_block a {
    text-decoration: underline;
    color: theme("colors.interactive-secondary-default");
    font-weight: 600;
  }
  
  .agreement_block a:hover {
    text-decoration: none;
  }
  
  .agreement_block a:focus {
    text-decoration: underline;
    background-color: #30658229;
  }
  
  @media screen and (min-width: 600px) {
    .agreement_block a {
      color: theme("colors.surface-neutral-option-1");
    }
  
    .agreement_block a:focus {
      background-color: #30658229;
    }
  }
  
  .content_box ul {
    padding-left: 16px;
  }
  
  .content_box ul li {
    list-style-type: disc;
    line-height: 24px;
    margin-bottom: 8px;
  }
  
  .content_box ul li:last-of-type {
    margin-bottom: 0;
  }
  
  .content_box ol {
    padding-left: 16px;
  }
  
  .content_box ol li {
    list-style-type: decimal;
    line-height: 24px;
    margin-bottom: 8px;
  }
  
  .content_box ol li:last-of-type {
    margin-bottom: 0;
  }
  
  .content_box a {
    text-decoration: underline;
  }
  
  .icon_btn:hover [class~="btnCompLibrary_body"] svg {
    fill: theme("colors.interactive-action-hovered");
  }
  .icon_btn:focus {
    border-radius: 50%;
    outline-color: theme("colors.interactive-focus-default");
    outline-offset: 2px;
  }
  .icon_btn:active [class~="btnCompLibrary_body"] svg {
    fill: theme("colors.interactive-secondary-default");
  }
  .icon_btn:active [class~="btnCompLibrary_body"] svg path {
    stroke: theme("colors.surface-neutral-option-1");
  }
  