.removeBtn [class~="btnLabel"] {
  position: relative;
  display: inline !important;
  background: linear-gradient(
    to right,
    theme("colors.text-critical"),
    theme("colors.text-critical")
  );
  background-color: none;
  background-size: 100% 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition:
    background-color 0.5s ease-out,
    background-size 0.5s;
}

.removeBtn:hover [class~="btnLabel"] {
  background-color: theme("colors.text-critical");
  color: theme("colors.icon-on-dark");
  transition:
    background-color 0.7s ease-in,
    background-size 0.5s;
  background-size: 0% 2px;
  padding: 0 4px;
}

.removeBtn[class~="focused-modal"]:focus {
  outline: 0 !important;
}

.disabledPromoBtn[class~="staysure"]:disabled:hover {
  border-color: theme("colors.border-disabled") !important;
}
