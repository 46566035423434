.btnLabel {
  position: relative;
}

.btnLabel::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background: theme("colors.interactive-secondary-default");
  transition: 0.5s;
}

.btnLabel:hover::after {
  width: 0;
}

.btnLabel:active {
  border: none !important;
  transition: background-color 0.5s ease-in;
}

.btnLabel:hover {
  background-color: theme("colors.interactive-secondary-default");
  color: theme("colors.surface-neutral-option-2");
  transition: background-color 0.5s ease-in;
}

.btnLabel:focus {
  outline: "none" !important;
}

.buttonFocused {
  outline: 2px solid theme("colors.interactive-focus-default");
  outline-offset: 2px;
  border-radius: 80px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.closeBtn:focus-visible {
  outline: 2px solid theme("colors.interactive-focus-default") !important;
  outline-offset: 2px !important;
  border-radius: 80px;
  padding-left: 8px;
  padding-right: 8px;
}

.openBtn:focus-visible {
  outline: 2px solid theme("colors.interactive-focus-default") !important;
  outline-offset: 2px !important;
  border-radius: 80px;
  padding-left: 8px;
  padding-right: 8px;
}

.myDrawerStyle {
  height: "100%";
}

.closeBtnWrapper {
  display: flex;
  justify-content: flex-end;
}

.closeBtnWrapper:focus-visible {
  outline: 2px solid theme("colors.interactive-focus-default") !important;
  outline-offset: 2px !important;
  border-radius: 80px;
  padding-left: 8px;
  padding-right: 8px;
}

.drawerWrapper ul {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.drawerWrapper ol {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
