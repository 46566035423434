.question_Paragraph_wrapper p:not(:last-of-type) {
  margin-bottom: 8px;
}

.question_Paragraph_wrapper ul {
  padding-left: 19px;
}

.question_Paragraph_wrapper ol {
  padding-left: 19px;
}
.newMedicalStaysureSubTitle {
  text-align: center;
  color: theme("colors.text-body") !important;

}

.newMedicalAvantiSubTitle {
  text-align: center;
  color: theme("colors.text-body") !important;

}
