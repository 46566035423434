.help_block_inner {
  background: rgb(165, 102, 173);
  background: linear-gradient(
    90deg,
    rgba(165, 102, 173, 0.5494572829131652) 0%,
    rgba(165, 102, 173, 0.8743872549019608) 0%,
    rgba(154, 93, 164, 1) 20%,
    rgba(82, 35, 103, 1) 100%
  );
}

.curved_corner_top_right {
  width: 26px;
  height: 26px;
  overflow: hidden;
  top: 0;
  left: -24px;
}

.curved_corner_top_right::before {
  top: 1px;
  right: 2px;
  box-shadow: 13px -13px 0 0 #f0f0f0;
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  border-radius: 50%;
}

.curved_corner_bottom_right {
  width: 16px;
  height: 16px;
  overflow: hidden;
  bottom: -15px;
  right: 0;
}

.curved_corner_bottom_right::after {
  top: 0;
  right: 0;
  box-shadow: 8px -8px 0 0 #f0f0f0;
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  border-radius: 50%;
}

.country_wrapper [class~="dropdown_component_library"] {
  @apply border border-interactive-secondary-default rounded-radius-lg max-h-[56px] lg:pl-spacing-l;
  width: 100%;
}

.country_wrapper [class~="dropdown_component_library"] [class~="country-name"] {
  @apply pl-0;
}

.country_wrapper [class~="dropdown_component_library"][class~="filled"],
.country_wrapper
  [class~="dropdown_component_library"][class~="focused_without_selectitem"] {
  outline: 2px solid #368fe9;
  outline-offset: 4px;
}

.country_wrapper [class~="dropdown_component_library"][class~="filled"],
.country_wrapper
  [class~="dropdown_component_library"][class~="focused_without_selectitem"][aria-expanded="true"] {
  outline: unset;
}

.country_wrapper [class~="selected"] {
  border-width: 2px;
}

.country_wrapper [class~="hovered"] {
  /* border-color: rgb(206, 206, 206);
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4);
  background: theme("colors.surface-neutral-option-2");
  border-width: 2px; */
  border-width: 2px !important;
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4);
  background: theme("colors.surface-neutral-option-2");
}

.country_wrapper [class~="hovered"][class~="dropdown_component_library"] {
  @apply lg:pl-[23px] pl-[15px] pr-[15px] pt-[15px] pb-[15px];
}

.country_wrapper
  [class~="hovered"][class~="dropdown_component_library"]:has(span) {
  @apply lg:pl-[24px] pl-[16px] pr-[16px] pt-[16px] pb-[16px];
}

.country_wrapper [class~="keyBoardFocusedInBtn"] {
  outline: 2px solid #368fe9;
  outline-offset: 4px;
}
.country_wrapper div:nth-child(1) {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.country_wrapper div:nth-child(2) {
  align-items: center;
}

.country_wrapper [class~="hint_label"] {
  display: none;
}

.country_wrapper [class~="icon_and_placeholdertext"] {
  display: flex;
}

.country_wrapper [class~="placeholder_text_component_library"] {
  @apply text-2xs font-semibold leading-[24px] text-left !ml-0 text-text-on-primary bg-transparent;
}

.country_wrapper [class~="country-name"] {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.country_wrapper [class~="country-name"]:hover {
  background: theme("colors.surface-neutral-option-2");
}

.country_tag_search_wrapper {
  border: none;
}

.country_tag_search_wrapper > div {
  width: 100%;
}

.country_tag_search_wrapper [class~="MuiAutocomplete-root"] {
  width: 100%;
  border: none !important;
}

.country_tag_search_wrapper [class~="MuiOutlinedInput-root"] {
  border: 1px solid #306582 !important;
  border-radius: 8px;
  width: 100% !important;
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
  padding-left: 0 !important;
  padding-right: 12px !important;
}

.country_tag_search_wrapper [class~="MuiOutlinedInput-root"]:hover {
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4);
  border-width: 2px !important;
}

.country_tag_search_wrapper
  [class~="MuiOutlinedInput-root"]:has([class~="MuiChip-label"]) {
  border-width: 2px !important;
}

.country_tag_search_wrapper [class~="MuiChip-label"] {
  @apply !border-[2px] font-body-font text-2xs font-bold leading-[24px];
}

.country_tag_search_wrapper [class~="MuiAutocomplete-hasClearIcon"],
.country_tag_search_wrapper [class~="Mui-focused"] {
  border-width: 2px !important;
}

/* .country_tag_search_wrapper [class~="MuiOutlinedInput-root"] {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 0 !important;
} */

.country_tag_search_wrapper [class~="MuiInputLabel-root"] {
  font-family: theme("fontFamily.body-font");
  font-size: theme("fontSize.2xs");
  font-weight: theme("fontWeight.semibold");
  line-height: 24px;
  color: theme("colors.surface-neutral-option-8");
}

.country_tag_search_wrapper [class~="MuiInputBase-adornedStart"] {
  @apply lg:!pl-[22px] !pl-[14px];
}

.country_tag_search_wrapper [class~="MuiFormLabel-root"] {
  @apply lg:pl-spacing-s pl-spacing-xxs;
}

.country_tag_search_wrapper
  [class~="MuiAutocomplete-root"][class~="Mui-focused"] {
  outline: 2px solid #368fe9;
  outline-offset: 4px;
}

.country_tag_search_wrapper
  [class~="MuiAutocomplete-root"][class~="Mui-expanded"][class~="Mui-focused"] {
  outline: unset !important;
}

.country_tag_search_wrapper
  [class~="MuiAutocomplete-root"]
  [class~="MuiOutlinedInput-root"]
  [class~="MuiAutocomplete-input"] {
  @apply lg:!pl-spacing-l !pl-spacing-m;
}
