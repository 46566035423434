.list_block ul {
  list-style-type: disc;
  padding-left: 24px;
  font-weight: 400;
  font-size: 18px;
}

.list_block ul li {
  line-height: 27px;
  /* margin-bottom: 16px; */
}

.list_block ul li:last-of-type {
  margin-bottom: 0;
}

.richtext a {
  word-break: keep-all !important;
  overflow-wrap: normal !important;
}

@media (max-width: 904px) {
  .logo[class~="staysure"] img {
    width: 162.11px !important;
    max-width: unset !important;
  }
}

@media (min-width: 904px) {
  .logo[class~="staysure"] img {
    width: 163.852px !important;
    max-width: unset !important;
  }
}

@media (max-width: 904px) {
  .logo[class~="avanti"] img {
    width: 131px !important;
    max-width: unset !important;
  }
}

@media (min-width: 904px) {
  .logo[class~="avanti"] img {
    width: 131px !important;
    max-width: unset !important;
  }
}

.coverImageBannerHeight[class~="staysure"] {
  @apply h-full;
}

.coverImageBannerHeight[class~="avanti"] {
  @apply h-full md:h-[320px] lg:min-h-full;
}

.coverImageBanner[class~="avanti"] {
  @apply bg-[rgb(255,216,115)] w-3/5;
}

.coverImageBanner[class~="staysure"] {
  @apply bg-gradient-to-r from-white to-transparent w-1/2;
}
