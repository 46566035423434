.drawerContent ul {
  margin-left: 16px;
}

.drawerContent ul li {
  list-style-type: "•";
  margin-bottom: 12px !important;
  padding-left: 12px;
  font-family: theme("fontFamily.body-font");
  font-size: 18px;
  line-height: 150%;
  font-weight: 400;
}

.drawerContent ul li:first-child {
  margin-top: 12px;
}

.drawerContent ul li:last-of-type {
  margin-bottom: 0;
}

.drawerContent ol {
  margin-left: 28px;
}

.drawerContent ol li {
  list-style-type: number;
  margin-bottom: 12px !important;
  font-family: theme("fontFamily.body-font");
  font-size: 18px;
  line-height: 150%;
  font-weight: 400;
}

.drawerContent ul ol:first-child {
  margin-top: 12px;
}

.drawerContent ol li:last-of-type {
  margin-bottom: 0;
}

.closeBtn [class~="btnCompLibrary_rightIcon"] svg path {
  transform: scale(1.2) translate(-2px, -2px) !important;
}
