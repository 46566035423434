.primary [class~="btnCompLibrary_leftIcon"] {
  margin-right: theme("spacing.spacing-xxs");
}

.secondary [class~="btnCompLibrary_leftIcon"] {
  margin-right: theme("spacing.spacing-xxs");
}

.secondary [class~="btnCompLibrary_leftIcon"] path {
  stroke: theme("colors.interactive-secondary-default");
}

.secondary:active [class~="btnCompLibrary_leftIcon"] path {
  stroke: theme("colors.dark.icon-on-secondary");
}

.primary[class~="btnCompLibrary_disabled"] path {
  stroke: rgba(179, 179, 179, 1);
}

.secondary[class~="btnCompLibrary_btnType_icon"]
  [class~="btnCompLibrary_wrapper"] {
  display: block !important;
}

.secondary[class~="staysure"]:hover {
  border-color: theme("colors.interactive-secondary-pressed") !important;
}

.secondary[class~="expat"]:hover {
  border-color: theme("colors.interactive-secondary-pressed") !important;
}

.secondary[class~="avanti"]:hover {
  border-color: theme("colors.interactive-secondary-hovered") !important;
}

.icon_btn_secondary:active [class~="btnCompLibrary_body"] svg path {
  stroke: theme("colors.dark.icon-on-secondary");
}

/* .icon_btn_secondary [class~="btnCompLibrary_body"] svg path {
  stroke: theme("colors.interactive-secondary-default");
} */

.tertiary_light [class~="btnCompLibrary_rightIcon"] svg {
  padding-left: theme("spacing.spacing-xxs");
}

.tertiary_light [class~="btnCompLibrary_rightIcon"] svg path {
  stroke: theme("colors.interactive-secondary-default");
}

.tertiary_light [class~="btnCompLibrary_lefttIcon"] svg {
  padding-left: theme("spacing.spacing-xxs");
}

.tertiary_light [class~="btnCompLibrary_lefttIcon"] svg path {
  stroke: theme("colors.interactive-secondary-default");
}

.tertiary_light [class~="btnLabel"] {
  position: relative;
  display: inline !important;
  background: linear-gradient(
    to right,
    theme("colors.interactive-secondary-default"),
    theme("colors.interactive-secondary-default")
  );
  background-color: none;
  background-size: 100% 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition:
    background-color 0.5s ease-out,
    background-size 0.5s;
}

.tertiary_light_error [class~="btnCompLibrary_rightIcon"] svg {
  padding-left: theme("spacing.spacing-xxs");
} 

.tertiary_light_error [class~="btnCompLibrary_rightIcon"] svg path {
  stroke: theme("colors.interactive-secondary-default");
}

.tertiary_light_error [class~="btnCompLibrary_lefttIcon"] svg {
  padding-left: theme("spacing.spacing-xxs");
}

.tertiary_light_error [class~="btnCompLibrary_lefttIcon"] svg path {
  stroke: theme("colors.interactive-secondary-default");
}

.tertiary_light_error [class~="btnLabel"] {
  position: relative;
  display: inline !important;
  background: linear-gradient(
    to right,
    theme("colors.text-critical"),
    theme("colors.text-critical")
  );
  background-color: none;
  background-size: 100% 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition:
    background-color 0.5s ease-out,
    background-size 0.5s;
}

.tertiary_light_error:hover [class~="btnLabel"] {
  background-color: theme("colors.text-critical");
  transition:
    background-color 0.7s ease-in,
    background-size 0.5s;
  background-size: 0% 2px;
}

.tertiary_light:hover [class~="btnLabel"] {
  background-color: theme("colors.interactive-secondary-default");
  transition:
    background-color 0.7s ease-in,
    background-size 0.5s;
  background-size: 0% 2px;
}

.tertiary_light [class~="btnCompLibrary_body"] {
  display: inline !important;
}

/* //  tertiary dark button */
.tertiary_dark [class~="btnCompLibrary_rightIcon"] svg {
  padding-left: theme("spacing.spacing-xxs");
}

.tertiary_dark [class~="btnCompLibrary_rightIcon"] svg path {
  stroke: theme("colors.dark.icon-on-secondary");
}

.tertiary_dark [class~="btnCompLibrary_lefttIcon"] svg {
  padding-left: theme("spacing.spacing-xxs");
}

.tertiary_dark [class~="btnCompLibrary_lefttIcon"] svg path {
  stroke: theme("colors.dark.icon-on-secondary");
}

.tertiary_dark [class~="btnLabel"] {
  position: relative;
  display: inline !important;
  background: linear-gradient(
    to right,
    theme("colors.dark.border-strong"),
    theme("colors.dark.border-strong")
  );
  background-color: none;
  background-size: 100% 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition:
    background-color 0.5s ease-out,
    background-size 0.5s;
}

.tertiary_dark:hover [class~="btnLabel"] {
  background-color: theme("colors.surface-neutral-option-2");
  transition:
    background-color 0.7s ease-in,
    background-size 0.5s;
  background-size: 0% 2px;
}

.tertiary_dark [class~="btnCompLibrary_body"] {
  display: inline !important;
}

.tertiary_light_icon:active svg path {
  stroke: theme("colors.dark.icon-on-secondary");
}

.focus_class[class~="keyBoardFocused"]:focus-visible{
  outline: 2px solid theme("colors.interactive-focus-default");
  outline-offset: 2px;
}

.secondary.focus_class[class~="keyBoardFocused"]:focus {
  border-color: theme("colors.border-strong");
}

.icon_btn_secondary_dark [class~="btnCompLibrary_body"] svg path {
  stroke: theme("colors.dark.icon-on-secondary");
}

.icon_btn_secondary_dark:hover [class~="btnCompLibrary_body"] svg path {
  stroke: theme("colors.dark.interactive-secondary-default");
}

.icon_btn_secondary_dark:active [class~="btnCompLibrary_body"] svg path {
  stroke: theme("colors.dark.icon-on-secondary");
}

.icon_btn_secondary_dark.focus_class[class~="keyBoardFocused"]:focus {
  border-color: theme("colors.dark.border-strong-2");
  outline-offset: 4px;
}

.left_Icon_margin [class~="btnCompLibrary_leftIcon"] {
  margin-right: theme("spacing.spacing-xs");
}

.right_Icon_margin [class~="btnCompLibrary_rightIcon"] {
  margin-left: theme("spacing.spacing-xs");
}

.CoverSelectionBtnWrapper:hover svg path {
  stroke: #ffd873;
}

.CoverSelectionBtnWrapper:active svg path {
  stroke: #faa019;
}
