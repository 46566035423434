.content_box ul {
  padding-left: 16px;
}

.content_box ul li {
  list-style-type: disc;
  line-height: 24px;
  margin-bottom: 8px;
}

.content_box ul li:last-of-type {
  margin-bottom: 0;
}

.content_box ol {
  padding-left: 16px;
}

.content_box ol li {
  list-style-type: decimal;
  line-height: 24px;
  margin-bottom: 8px;
}

.content_box ol li:last-of-type {
  margin-bottom: 0;
}

.content_box a {
  text-decoration: underline;
}
