.acoBtnInactive[class~="componnt_lib_toggle_btn_active"]:hover {
  border-width: 2px !important;
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4) !important;
  background: theme("colors.dark.text-on-secondary") !important;
}

.acoBtnActive [class~="componnt_lib_toggle_btn_wraper"] {
  width: 100%;
}

.acoBtnActive[class~="componnt_lib_toggle_btn_active"]:hover {
  background: theme("colors.interactive-secondary-default") !important;
  color: theme("colors.dark.text-title") !important;
  border-color: theme("colors.icon-on-secondary") !important;
  box-shadow: none;
}

.acoBtnActive[class~="componnt_lib_toggle_btn_inactive"]:hover {
  background: theme("colors.interactive-secondary-default") !important;
  color: theme("colors.dark.text-title") !important;
  border-color: theme("colors.icon-on-secondary") !important;
  box-shadow: none;
  border-width: 1px !important;
}

.acoBtnActive [class~="componnt_lib_toggle_btn_body_wrapper"] {
  width: 100% !important;
  display: flex !important;
  float: left;
  justify-content: flex-start;
}

.acoBtnInactive [class~="componnt_lib_toggle_btn_body_wrapper"] {
  width: 100%;
  justify-content: center;
}

.acoBtnInactive:hover [class~="componnt_lib_toggle_btn_body_wrapper"] {
  width: 100%;
  justify-content: center;
}

.acoBtnActive [class~="componnt_lib_toggle_btn_body"] {
  width: calc(100% + (100% - 28px));
  margin-left: theme("spacing.spacing-xxs");
  text-align: center;
}

.acoBtnActive[class~="focused-modal"]:focus {
  outline: 0 !important;
}

.acoBtnInactive[class~="focused-modal"]:focus {
  outline: 0 !important;
}

.textSelectionButton [class~="btnLabel"] {
  background-size: 100% 1px !important;
  background-position: 0 100% !important;
}

.textSelectionButton[class~="focused-modal"]:focus {
  background: transparent !important;
}

.removeBtn [class~="btnLabel"] {
  position: relative !important;
  display: inline !important;
  background: linear-gradient(
    to right,
    theme("colors.text-critical"),
    theme("colors.text-critical")
  ) !important;
  background-color: none !important;
  background-size: 100% 2px !important;
  background-position: 0 100% !important;
  background-repeat: no-repeat !important;
  transition:
    background-color 0.5s ease-out,
    background-size 0.5s !important;
}

.removeBtn:hover [class~="btnLabel"] {
  background-color: theme("colors.text-critical") !important;
  transition:
    background-color 0.7s ease-in,
    background-size 0.5s !important;
  background-size: 0% 2px !important;
}

.removeBtn[class~="focused-modal"]:focus {
  outline: 0 !important;
}

.acoDropDownWrapper [class~="hint_label"] {
  display: none;
}

.golfDropdownActive [class~="aco_dropdown_button_body_component_library"] {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: 100%;
}

.golfDropdownInactive [class~="aco_dropdown_button_body_component_library"] {
  display: flex;
  align-items: center;
  justify-content: center !important;
  width: 100%;
}

.golfDropdownInactive [class~="aco_arrow_head_icon_component_library"] {
  margin-left: 4px;
}

.golfDropdownInactive svg path {
  stroke: theme("colors.interactive-secondary-default") !important;
  stroke-width: 2px !important;
}

.golfDropdownInactive:hover {
  border-width: 2px !important;
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4) !important;
  background: theme("colors.interactive-action-hovered") !important;
}

.golfDropdownActive:hover {
  border-width: 2px !important;
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4) !important;
}

.acoTitle[class~="avn-header"] {
  font-family: theme("fontFamily.header-font");
  font-weight: 700;
}

@media (min-width: 600px) {
  .acoTitle[class~="avn-header"] {
    font-family: theme("fontFamily.semibold-font");
    font-weight: 600 !important;
  }
}

@media (min-width: 904px) {
  .acoTitle[class~="avn-header"] {
    font-family: theme("fontFamily.header-font");
    font-weight: 700 !important;
  }
}
