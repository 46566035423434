.list_block ul {
  list-style-type: disc;
  padding-left: 24px;
  font-weight: 400;
  font-size: 16px;
}

.list_block ul li {
  line-height: 24px;
  margin-bottom: 16px;
}

.list_block ul li:last-of-type {
  margin-bottom: 0;
}

.richtext a {
  word-break: keep-all !important;
  overflow-wrap: normal !important;
}

.expand_btn [class~="btnLabel"] {
  position: relative;
  display: inline !important;
  background-color: none;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition:
    background-color 0.5s ease-out,
    background-size 0.5s;
}

.expand_btn:hover [class~="btnLabel"] {
  background-color: theme("colors.surface-neutral-option-1");
  color: theme("colors.interactive-secondary-default");
  transition:
    background-color 0.7s ease-in,
    background-size 0.5s;
}

