.medical_question_btn_wrapper [class~="btn-group-wrapper"] {
  @apply m-0 gap-spacing-s;
}

.medical_question_btn_wrapper
  [class~="btn-group-wrapper"]
  [class~="componnt_lib_toggle_btn"] {
  @apply mb-0;
}

.cover_denied_staysure a {
  font-weight: bold;
  text-decoration: underline;
  color: #272427;
}

.cover_denied_avanti a {
  font-weight: bold;
  text-decoration: underline;
  @apply text-interactive-secondary-default
}
