.readMoreLabel {
  position: relative;
  color: theme("colors.surface-neutral-option-1");
}

.readMoreLabelMDFlow {
  position: relative;
  color: theme("colors.interactive-secondary-default");
}


.accordionHeader {
  color: theme("colors.black");
}

.accordionHeader ul,
.accordionHeader ol {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.readMoreLabel::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background: theme("colors.surface-neutral-option-1");
  transition: 0.5s;
}

.readMoreLabelMDFlow::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background: theme("colors.interactive-secondary-default");
  transition: 0.5s;
}

@media (min-width: 904px) {
  .readMoreLabel:active {
    border: none !important;
    transition: background-color 0.5s ease-in;
  }

  .readMoreLabel:hover::after {
    width: 0;
  }

  .readMoreLabel:hover {
    background-color: theme("colors.surface-neutral-option-1");
    color: theme("colors.surface-promo");
    transition: background-color 0.5s ease-in;
  }
}

@media (min-width: 904px) {
  .readMoreLabelMDFlow:active {
    border: none !important;
    transition: background-color 0.5s ease-in;
  }

  .readMoreLabelMDFlow:hover::after {
    width: 0;
  }

  .readMoreLabelMDFlow:hover {
    background-color: theme("colors.interactive-secondary-default");
    color: theme("colors.surface-neutral-option-1");
    transition: background-color 0.5s ease-in;
  }
}

.readMoreLabel:focus {
  outline: none !important;
}

.readMoreLabelMDFlow:focus {
  outline: none !important;
}

.readMoreLabel [class~="btnLabel"] {
  line-height: 18px;
}

.readMoreLabelMDFlow [class~="btnLabel"] {
  line-height: 18px;
}
.focusReadMoreLabel:focus-visible {
  outline: 2px solid theme("colors.interactive-focus-default") !important;
  outline-offset: 2px !important;
  outline-width: 2px;
  border-radius: 8px;
}

.descriptionAnswerWrapper:has([class~="answer-block"])
  [class~="load-more-wrapper"] {
  @apply xl:px-spacing-5xl lg:px-spacing-m tablet:px-spacing-6xl px-spacing-m  w-full;
}

.descriptionAnswerWrapper:has([class~="answer-block"])
  [class~="load-more-wrapper"]
  [class~="load-more-inner-wrapper"] {
  @apply lg:max-w-[325px] md:max-w-[432px] mx-auto md:justify-start;
}

.accordionHeader [class~="ExpanderInteractive_accordianContainer__vKPj7"] {
  border-radius: 8px !important;
}

.descriptionAnswerWrapper ul {
  list-style-type: disc;
}

.descriptionAnswerWrapper ol {
  list-style-type: decimal;
}

.descriptionAnswerWrapper a {
  @apply underline font-semibold text-neutral-50;
  word-break: break-word;
}

.descriptionAnswerWrapper a:hover {
  @apply no-underline;
}
