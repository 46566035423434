.editFinishButton > div > button {
  max-width: 100%;
}

.cancelEditButton {
  font-size: theme("fontSize.xs");
  font-family: theme("fontFamily.body-font");
  font-weight: 600;
  line-height: theme("fontSize.xs");
  height: 17px;
  margin-bottom: theme("spacing.spacing-l");
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.btnLink {
  color: theme("colors.interactive-secondary-default");
  font: 700 theme("fontSize.base-font-size") / 150%
    theme("fontFamily.sub-section-header-font");
}

.btnLink:hover {
  color: theme("colors.surface-neutral-option-1");
  background: theme("colors.interactive-secondary-default");
  transition:
    background-color 0.5s ease-out,
    background-size 0.5s;
}

.btnLink:focus {
  border-radius: 20px;
}
