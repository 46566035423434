.toggle_btn_sts [class~="componnt_lib_toggle_btn_wraper"] {
  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
}

.toggle_btn_sts[class~="componnt_lib_toggle_btn_active"] {
  background: theme("colors.interactive-secondary-default");
  color: theme("colors.dark.text-title");
  border-color: theme("colors.icon-on-secondary") !important;
  /* margin-bottom: 0px !important; */
  @apply md:!mb-0;
}

.toggle_btn_sts[class~="componnt_lib_toggle_btn_active"]:hover {
  border-width: 2px !important;
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4);
  background: theme("colors.interactive-secondary-pressed") !important;
  /* margin-bottom: -2px !important; */
  @apply md:!mb-[-2px];
}
.toggle_btn_sts[class~="componnt_lib_toggle_btn_inactive"] {
  border-color: theme("colors.interactive-secondary-default") !important;
  /* margin-bottom: 0px; */
  @apply md:mb-0 mb-spacing-s;
}
.toggle_btn_sts[class~="componnt_lib_toggle_btn_inactive"]:hover {
  border-width: 2px !important;
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4);
  /* margin-bottom: -2px !important; */
  @apply md:!mb-[-2px];
}
.toggle_btn_sts[class~="componnt_lib_toggle_btn_active"]:focus {
  border-color: theme("colors.interactive-secondary-default") !important;
  background: theme("colors.interactive-secondary-default");
  outline: none;
}

.toggle_btn_sts[class~="componnt_lib_toggle_btn_active"]:active {
  border-color: theme("colors.interactive-secondary-default") !important;
  outline: none;
}

.toggle_btn_sts[class~="keyBoardFocused"]:focus-visible {
  outline: 2px solid theme("colors.interactive-focus-default");
  outline-offset: 2px;
}

.toggle_btn_sts[class~="keyBoardFocused"][class~="componnt_lib_toggle_btn_active"]:focus-visible {
  outline: 2px solid theme("colors.interactive-focus-default");
  outline-offset: 2px;
}

/* Avnti  */

.toggle_btn_avn [class~="componnt_lib_toggle_btn_wraper"] {
  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
}

.toggle_btn_avn[class~="componnt_lib_toggle_btn_active"] {
  background: theme("colors.interactive-secondary-default");
  color: theme("colors.dark.text-title");
  border-color: theme("colors.interactive-secondary-default") !important;
  /* margin-bottom: 0px; */
  @apply md:!mb-0;
}

.toggle_btn_avn[class~="componnt_lib_toggle_btn_active"]:hover {
  border-width: 2px !important;
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4);
  background: theme("colors.interactive-secondary-pressed") !important;
  /* margin-bottom: -2px !important; */
  @apply md:!mb-[-2px];
}
.toggle_btn_avn[class~="componnt_lib_toggle_btn_inactive"] {
  color: theme("colors.text-title");
  border-color: theme("colors.interactive-secondary-default") !important;
  /* margin-bottom: 0px; */
  @apply md:mb-0 mb-spacing-s;
}

.toggle_btn_avn[class~="componnt_lib_toggle_btn_inactive"]:hover {
  border-width: 2px !important;
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4);
  /* margin-bottom: -2px !important; */
  @apply md:!mb-[-2px];
}

.toggle_btn_avn[class~="componnt_lib_toggle_btn_active"]:focus {
  border-color: theme("colors.interactive-secondary-default") !important;
  background: theme("colors.interactive-secondary-default");
  outline: none;
}

.toggle_btn_avn[class~="componnt_lib_toggle_btn_active"]:active {
  border-color: theme("colors.interactive-secondary-default") !important;
  outline: none;
}

.toggle_btn_avn[class~="keyBoardFocused"]:focus-visible {
  border-color: theme("colors.surface-accent-1-secondary") !important;
  outline: 2px solid theme("colors.interactive-focus-default");
  outline-offset: 2px;
}

.toggle_btn_avn[class~="keyBoardFocused"][class~="componnt_lib_toggle_btn_active"]:focus-visible {
  border-color: theme("colors.interactive-secondary-default") !important;
  outline: 2px solid theme("colors.interactive-focus-default");
  outline-offset: 2px;
}

.toggle_btn_avn[class~="keyBoardFocused"][class~="componnt_lib_toggle_btn_inactive"]:hover {
  border-color: theme("colors.interactive-secondary-default") !important;
  outline: none;
}
