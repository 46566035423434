.findoutBtn {
  @apply border-surface-neutral-option-1 text-icon-on-dark leading-custom-size-xs
}
.ghicPromotion .findoutBtn:hover {
    @apply bg-surface-neutral-option-1 text-text-on-secondary !border-surface-neutral-option-1
 }

 .findoutBtn:active {
    @apply bg-interactive-secondary-pressed border-transparent
 }
 .findoutBtn:focus {
    @apply border-surface-neutral-option-1 outline outline-2 outline-offset-4 outline-interactive-focus-default;   
 }

 .ghicPromotion .findoutBtn[class~="keyBoardFocused"]:focus {
   border-color: theme("colors.surface-neutral-option-1");
 }