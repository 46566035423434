.coverTypeRichText p {
  @apply text-xs break-words font-body-font leading-[27px] text-confirmation-body 
  mt-spacing-l md:mt-spacing-s md:pb-0 pb-spacing-xs hover:no-underline md:text-left text-center;
}

.coverTypeRichText a {
  @apply hover:no-underline text-xs leading-[27px];
}

.coverTypeRichText ul,
.coverTypeRichText ol {
  @apply pl-spacing-m;
}

.coverTypeRichText ul li {
  @apply list-disc text-xs leading-[27px];
}

.coverTypeRichText ol li {
  @apply list-decimal text-xs leading-[27px];
}
